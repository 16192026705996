import { Action, ActionCreator } from 'redux';
import { ThunkDispatch, ThunkAction } from "redux-thunk/index";
import { RootState } from '..';
import { DevelopersAPI } from '../../api/DevelopersAPI';
import { Developers, UpdateDevelopers } from '../../api/types';
import { 
    DevelopersActionTypes, ErrorType, IAuthorizedAction, 
    IFechErrorAction, IFechSuccessAction, IGetDeveloperInfoAction, 
    ILogout, ITokenAction, SuccessType, IUpdateDeveloperInfoAction 
} from './types';


export type DevelopersActions =
    | ITokenAction
    | IFechSuccessAction
    | IFechErrorAction
    | IAuthorizedAction
    | IGetDeveloperInfoAction
    | IUpdateDeveloperInfoAction
    | ILogout;

export const getTokenAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, ITokenAction>> = 
    (payload: Developers, isAuthorized: boolean) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await DevelopersAPI.developers(payload);

            const successAction: ITokenAction = {
                type: DevelopersActionTypes.GET_TOKEN,
                token: result.token,
                isAuthorized
            };

            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const loginAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, ITokenAction>> = 
    (email: string, password: string, isAuthorized: boolean) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await DevelopersAPI.login(email, password);

            const successAction: ITokenAction = {
                type: DevelopersActionTypes.GET_TOKEN,
                token: result.token,
                isAuthorized
            };
            window.location.pathname = "/projects";
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const getDevelopersAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, ITokenAction>> = 
    (token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await DevelopersAPI.getDevelopers(token);

            const successAction: IGetDeveloperInfoAction = {
                type: DevelopersActionTypes.GET_DEVELOPER_INFO,
                developerInfo: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const updateDevelopersAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, ITokenAction>> = 
    (newDeveloperInfo: UpdateDevelopers, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await DevelopersAPI.updateDevelopers(newDeveloperInfo, token);
            console.log("result:", result);
            if(result.status === 200) {
                const successAction: IFechSuccessAction = {
                    type: DevelopersActionTypes.FETCH_SUCCESS,
                    successState: {
                        message: "You have successfully updated!",
                        success: true
                    },
                };
                dispatch(successAction);

                const res = await DevelopersAPI.getDevelopers(token);
                const successDeveloperAction: IGetDeveloperInfoAction = {
                    type: DevelopersActionTypes.GET_DEVELOPER_INFO,
                    developerInfo: res,
                };
                dispatch(successDeveloperAction);
            }
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export function isAuthorizedAction(isAuthorized: boolean) {
    const action: IAuthorizedAction = {
        type: DevelopersActionTypes.IS_AUTHORIZED,
        isAuthorized
    }
    return action;
}

export const logoutAction: ActionCreator<ILogout> = () => {
    window.location.pathname = "/";
    return {
        type: DevelopersActionTypes.LOGOUT
    }
}

export function successAction(success: SuccessType) {
    const action: IFechSuccessAction = {
        type: DevelopersActionTypes.FETCH_SUCCESS,
        successState: success
    }
    return action;
}

export function errorAction(error: ErrorType) {
    const action: IFechErrorAction = {
        type: DevelopersActionTypes.FETCH_ERROR,
        errorState: error
    }
    return action;
}