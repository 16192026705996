import { AppActions } from "./actions";
import { AppActionTypes, AppState } from './types';
import { Reducer } from "redux";

const defaultState: AppState = {
    showPhoneControl:false,
};

const defaultStateGetStorage: AppState =  JSON.parse(localStorage.getItem('appReducer') || '{}');
const defaultStateNew = Object.assign({}, defaultState, defaultStateGetStorage);

const appReducerPrivate: Reducer<AppState, AppActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case AppActionTypes.CHANGE_PHONE_CONTROL_STATE:
            {
                return {
                    ...state,
                    showPhoneControl: !state.showPhoneControl,
                };
            }
        default:
            return state;
    }
};

export const appReducer: Reducer<AppState, AppActions> = (
    state = defaultStateNew, action) => {
        const newState = appReducerPrivate(state, action);
        localStorage.setItem('appReducer', JSON.stringify(newState));
        return newState;
    }

export default appReducer;