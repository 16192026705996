import { DevelopersActions } from "./actions";
import { DevelopersActionTypes, DevelopersState } from './types';
import { Reducer } from "redux";
import { sessionStorageGetItem, sessionStorageRemoveItem, sessionStorageSetItem } from "../../utils/storage";

const defaultState: DevelopersState = {
    token: sessionStorageGetItem('token'),
    isAuthorized: false,
    errorState: {
        message: "",
        error: false
    },
    successState: {
        message: "",
        success: false
    },
    developerInfo: null
};

export const developersReducer: Reducer<DevelopersState, DevelopersActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case DevelopersActionTypes.GET_DEVELOPER_INFO:
            return {
                ...state,
                developerInfo: action.developerInfo
            };
        case DevelopersActionTypes.UPDATE_DEVELOPER:
            return {
                ...state,
                developerInfo: action.developerInfo,
            };
        case DevelopersActionTypes.GET_TOKEN:
            sessionStorageSetItem('token', action.token);
            return {
                ...state,
                token: action.token,
                isAuthorized: action.isAuthorized
            };
        case DevelopersActionTypes.IS_AUTHORIZED:
            return {
                ...state,
                isAuthorized: action.isAuthorized,
            };
        case DevelopersActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                successState: action.successState,
            };
        case DevelopersActionTypes.FETCH_ERROR:
            return {
                ...state,
                errorState: action.errorState,
            };
        case DevelopersActionTypes.LOGOUT:
            sessionStorageRemoveItem('token');
            return {
                ...state,
                token: null,
                isAuthorized: false,
            };
        default:
            return state;
    }
};
export default developersReducer;