import { Dispatch } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import ProfileDropdown from './ProfileDropdown';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function ProfileDropdownContainer(props: Props) {
    const { 
        developerInfo,
        logoutAction
    } = props;

    return (
        <ProfileDropdown 
            developerInfo={developerInfo}
            logoutAction={logoutAction} />
    );
}

const mapStateToProps = (state: RootState) => ({
    developerInfo: state.developers.developerInfo,
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    logoutAction: () => dispatch(actions.developers.logoutAction()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDropdownContainer);