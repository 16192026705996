import { Dispatch } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ApplicationTable } from './ApplicationTable';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function ApplicationTableContainer(props: Props) {
    const { 
        applications,
        project
    } = props;

    return (
        <ApplicationTable 
            applications={applications}
            project={project} />
    );
};

const mapStateToProps = (state: RootState) => ({
    applications: state.applications.applications,
    project: state.projects.project
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    getProjectsAction: (token: string) => 
        dispatch(actions.projects.getProjectsAction(token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationTableContainer)