import React from 'react';
import { Button } from 'react-materialize';
import { useHistory } from 'react-router-dom';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import { ProjectTableContainer } from '../ProjectTable';
import './Projects.scss';

type Props = {
  
};

export function Projects(props: Props) {
    const history = useHistory();
    return (
        <div className="container">
            <ProfileDropdownContainer />
            <div className="content projects">
                <div className="projects__head">
                    <TitleAndDesc 
                        title="Projects" 
                        desc="These are your projects" />
                    <Button
                        node="button"
                        waves="light"
                        className="projects__btn"
                        onClick={() => history.push('/new-project')}>
                        + Create New Project
                    </Button>
                </div>
                <ProjectTableContainer />
            </div>
        </div>
    );
};