import { Action} from 'redux';
import { AppActionTypes } from './types';


export interface IChangePhoneControlStateAction extends Action<AppActionTypes.CHANGE_PHONE_CONTROL_STATE> {}

export type AppActions =
    | IChangePhoneControlStateAction;

export function changePhoneControlStateAction() {
    const action: IChangePhoneControlStateAction = {
        type: AppActionTypes.CHANGE_PHONE_CONTROL_STATE
    }
    return action;
}