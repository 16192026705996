import React from 'react';
import { NavLink } from 'react-router-dom';
import { LogoWhiteIcon, DasboardIcon, BillingIcon, SquareIcon, ProjectsIcon, SettingIcon, DeveloperIcon, SupportIcon, DevelotexIcon } from '../../icons/icons';
import { Projects } from '../../store/projects/types';
import './Menu.scss';

type Props = {
    projects: Projects[]
}

const Menu:React.FC<Props> = (props) => {
    const { projects } = props;

    return (
        <div className="menu">
            <div className="menu__logo">
                <LogoWhiteIcon />
            </div>
            <ul className="menu__ul">
                <li className="menu__li">
                    <NavLink className="menu__link" to="/" exact>
                        <span className="menu__icon"><DasboardIcon /></span>
                        Dasboard
                    </NavLink>
                </li>
                <li className="menu__li">
                    <NavLink className="menu__link" to="/billing" exact>
                        <span className="menu__icon"><BillingIcon /></span>
                        Billing
                    </NavLink>
                </li>
                <li className="menu__li">
                    <NavLink className="menu__link" to="/projects" exact>
                        <span className="menu__icon"><ProjectsIcon /></span>
                        Projects
                    </NavLink>
                    <ul className="menu__dropdown">
                        {projects.map(project => (
                            <li 
                                key={`menu-${project.id}`}
                                className="menu__dropdown-li">
                                <NavLink className="menu__link" to={`/projects/${project.id}`}>
                                    <span className="menu__icon"><SquareIcon /></span>
                                    {project.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="menu__li">
                    <NavLink className="menu__link" to="/settings" exact>
                        <span className="menu__icon"><SettingIcon /></span>
                        Settings
                    </NavLink>
                </li>
                <li className="menu__li">
                    <NavLink className="menu__link" to="/developer-resources" exact>
                        <span className="menu__icon"><DeveloperIcon /></span>
                        Developer resources
                    </NavLink>
                </li>
                <li className="menu__li">
                    <NavLink className="menu__link" to="/support" exact>
                        <span className="menu__icon"><SupportIcon /></span>
                        Support
                    </NavLink>
                </li>
                <li className="menu__li">
                    <a className="menu__link" href="https://develotex.io/" target="_blank">
                        <span className="menu__icon"><DevelotexIcon /></span>
                        Develotex.com
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Menu;