import { Dispatch, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { NewApp } from './NewApp';
import * as yup from 'yup';
import { ApplicationParams } from '../../api/types';
import { Platform } from '../../api/types';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

export interface InitialValues {
    name: string;
    platform: Platform;
    description: string;
    bundleId?: string;
    p8CertificateBase64?: string;
    keyId?: string;
    teamId?: string;
    p12CertificateBase64?: string;
    p12CertificatePassword?: string;
    firebaseId?: number;
    firebaseKey?: string;
}

function NewAppContainer(props: Props) {
    const {
        createApplicationAction,
        token
    } = props;

    //@ts-ignore
    const { projectId } = props.match.params;

    const [certificate, setCertificate] = useState("P8");

    const certificateChange = (value: string) => {
        setCertificate(value);
    }

    const initialValues: InitialValues = { 
        name: "",
        platform: Platform.All,
        description: "",
        bundleId: undefined,
        p8CertificateBase64: undefined,
        keyId: undefined,
        teamId: undefined,
        p12CertificateBase64: undefined,
        p12CertificatePassword: undefined,
        firebaseId: undefined,
        firebaseKey: undefined,
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required("Application name is required"),
        platform: yup.string().required("Platform name is required"),
    });

    return (
        <NewApp 
            initialValues={initialValues}
            validationSchema={validationSchema} 
            certificate={certificate} 
            certificateChange={(value: string) => 
                certificateChange(value)} 
            createApplicationAction={createApplicationAction} 
            token={token} 
            projectId={projectId} />
    );
};

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token,
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    createApplicationAction: (payload: ApplicationParams, projectId: number, token: string) => 
        dispatch(actions.applications.createApplicationAction(payload, projectId, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAppContainer);