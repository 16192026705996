export type Developers = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    company: string;
    website: string;
}

export type UpdateDevelopers = {
    firstName: string;
    lastName: string;
    company: string;
    website: string;
    oldPassword?: string;
    password?: string;
}

export type ApplicationParams = {
    name: string;
    platform: Platform;
    description: string;
    bundleId?: string;
    p8CertificateBase64?: string;
    keyId?: string;
    teamId?: string;
    p12CertificateBase64?: string;
    p12CertificatePassword?: string;
    firebaseId?: number;
    firebaseKey?: string;
}

export enum Platform {
    Android = 1,
    IOS = 2,
    Web = 3,
    Desktop = 4,
    All = ""
}