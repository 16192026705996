import { StateType } from 'typesafe-actions';
import rootReducer from './root-reducer';

import * as appActions from './app/actions';
import * as developersActions from './developers/actions';
import * as projectsActions from './projects/actions';
import * as applicationsActions from './applications/actions';

export { default } from './store';
export { default as rootReducer } from './root-reducer';

export const selectors = {
};

export const actions = {
    app: appActions,
    developers: developersActions,
    projects: projectsActions,
    applications: applicationsActions
}

export type RootState = StateType<typeof rootReducer>;
