import { Dispatch, useEffect, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect, useStore } from 'react-redux';
import { actions, RootState } from '../../store';
import { Button, Modal } from 'react-materialize';
import './DeleteProjectModal.scss';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    open: boolean;
    projectName: string;
    deleteCloseClick: () => void;
    projectId: number;
};

function DeleteProjectModal(props: Props) {
    const {
        open,
        projectName,
        deleteCloseClick,
        deleteProjectAction,
        projectId,
        token
    } = props;

    const deleteProject = () => {
        if(token) {
            deleteProjectAction(projectId, token);
        }
        deleteCloseClick();
    }

    return (
        <Modal
            actions={[
                <>
                    <Button 
                        flat 
                        modal="close" 
                        node="button" 
                        waves="green"
                        onClick={deleteCloseClick}>Disagree</Button>
                    <Button 
                        flat 
                        modal="close" 
                        node="button" 
                        waves="green"
                        onClick={deleteProject}>Agree</Button>
                </>
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header="Delete project"
            id="deleteProjectModal"
            open={open}
            options={{
                dismissible: true,
                endingTop: '25%',
                inDuration: 250,
                opacity: 0.5,
                outDuration: 250,
                startingTop: '4%',
                onCloseEnd: () => deleteCloseClick()
            }}>
            <p>
                Do you really want to delete the <strong>{projectName}</strong> project?
            </p>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    deleteProjectAction: (projectId: number, token: string) => 
        dispatch(actions.projects.deleteProjectAction(projectId, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteProjectModal)