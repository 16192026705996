import { Dispatch, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { Settings } from './Settings';
import * as yup from 'yup';
import { UpdateDevelopers } from '../../api/types';

type Props =
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function SettingsContainer(props: Props) {
    const {
        developerInfo,
        updateDevelopersAction,
        token
    } = props;

    const [showKeySID, setShowKeySID] = useState(true);

    const validationSchema = yup.object().shape({
        firstName: yup.string().required("First Name name is required"),
        lastName: yup.string().required("Last Name name is required"),
        password: yup.string().min(14, 'Password should be of minimum 14 characters length'),
        repeatPassword: yup.string().when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
              [yup.ref("password")],
              "Both password need to be the same"
            )
        })
    });
    
    const toggleKeySIDClick = () => {
        setShowKeySID(!showKeySID);
    }

    return (
        <Settings 
            showKeySID={showKeySID}
            toggleKeySIDClick={toggleKeySIDClick} 
            validationSchema={validationSchema}
            developerInfo={developerInfo} 
            updateDevelopersAction={updateDevelopersAction} 
            token={token} />
    );
};

const mapStateToProps = (state: RootState) => ({
    developerInfo: state.developers.developerInfo,
    token: state.developers.token
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    fetchApplicationAction: (applicationId: number, token: string) => 
        dispatch(actions.applications.fetchApplicationAction(applicationId, token)),
    updateDevelopersAction: (newDeveloperInfo: UpdateDevelopers, token: string) => 
        dispatch(actions.developers.updateDevelopersAction(newDeveloperInfo, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsContainer);