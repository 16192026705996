import { Dispatch, useEffect, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { Applications } from './Applications';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DeleteProjectModal from '../DeleteProjectModal/DeleteProjectModal';
import { IApplication } from '../../store/applications/types';

type Props = any & 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function ApplicationsContainer(props: Props) {
    const { projectId } = props.match.params;
    const {
        currentProjectAction,
        token,
        project,
        editProjectAction,
        getApplicationsAction,
        currentApplicationAction
    } = props;

    const [deleteOpen, setDeleteOpen] = useState(false);

    const deleteOpenClick = () => {
        setDeleteOpen(true);
    }

    const deleteCloseClick = () => {
        setDeleteOpen(false);
    }

    const validationSchema = yup.object({
        name: yup.string().required("Project name is required"),
    });
    
    const formik = useFormik({
        initialValues: {
            name: "",
            description: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(token) {
                editProjectAction(projectId, values.name, values.description, token);
            }
        },
    });

    useEffect(() => {
        currentApplicationAction(null);
    }, []);
    
    useEffect(() => {
        if(project) {
            formik.setValues({"name": project.name, "description": project.description });
        }
    }, [project]);

    useEffect(() => {
        if(token) {
            currentProjectAction(projectId, token);
            getApplicationsAction(projectId, token);
        }
    }, [projectId]);
    
    return (
        <>
            <Applications 
                project={project} 
                formik={formik} 
                deleteOpenClick={deleteOpenClick} />
            {project && 
                <DeleteProjectModal 
                    open={deleteOpen} 
                    projectName={project.name} 
                    deleteCloseClick={deleteCloseClick} 
                    projectId={projectId} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token,
    project: state.projects.project
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    getApplicationsAction: (projectId: number, token: string) => 
        dispatch(actions.applications.getApplicationsAction(projectId, token)),
    currentProjectAction: (projectId: number, token: string) => 
        dispatch(actions.projects.currentProjectAction(projectId, token)),
    editProjectAction: (projectId: number, name: string, description: string, token: string) => 
        dispatch(actions.projects.editProjectAction(projectId, name, description, token)),
    currentApplicationAction: (application: IApplication | null) => 
        dispatch(actions.applications.currentApplicationAction(application)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationsContainer);