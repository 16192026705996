import { useFormik } from 'formik';
import * as yup from 'yup';
import { Support } from './Support';

export function SupportContainer() {
    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup.string().email().required("E-mail is required"),
        phoneNumber: yup.string().required("Phone number is required"),
        counrty: yup.string().required("Counrty is required")
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            counrty: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });
    return (
        <Support 
            formik={formik} />
    );
};