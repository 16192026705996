import React from 'react';
import { Dropdown } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import { BillingIcon, DownIcon, LogoutIcon, PersonIcon, SettingsIcon } from '../../icons/icons';
import { DeveloperInfo } from '../../store/developers/types';

type Props = {
    developerInfo: DeveloperInfo | null;
    logoutAction: () => void;
}

function ProfileDropdown(props: Props) {
    const { 
        developerInfo,
        logoutAction
    } = props;
    
    if(!developerInfo) {
        return null;
    }

    return (
        <div className="profile">
            <Dropdown
                id="Dropdown_6"
                options={{
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    container: null,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    outDuration: 250
                }}
                trigger={
                    <div className="profile__row">
                        <div className="profile__icon"><PersonIcon /></div>
                        {developerInfo.firstName} {developerInfo.lastName}
                        <div className="profile__down"><DownIcon /></div>
                    </div>
                }>
                <NavLink to="/billing" className="profile__link">
                    <div className="profile__link-icon"><BillingIcon /></div>
                    Billing and Account
                </NavLink>
                <NavLink to="/settings" className="profile__link">
                    <div className="profile__link-icon"><SettingsIcon /></div>
                    Settings
                </NavLink>
                <a 
                    className="profile__link"
                    onClick={logoutAction}>
                    <div className="profile__link-icon"><LogoutIcon /></div>
                    Log out
                </a>
            </Dropdown>
        </div>
    );
}

export default ProfileDropdown;