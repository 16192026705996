import { useEffect } from 'react';
import { Icon } from 'react-materialize';
import './Alert.scss';

type Props = {
    open: boolean;
    message: string;
    onClose: () => void;
    type: "success" | "error";
};

export function Alert(props: Props) {
    const {
        open,
        message,
        onClose,
        type
    } = props;

    useEffect(() => {
        if(open === true) {
            setTimeout(() => {
                onClose();
            }, 5000);
        }
    }, [open]);

    return (
        <>
            {open && 
                <div 
                    className={`alert ${open ? "show" : "hide"} ${type}`}>
                    <div>{message}</div>
                    <Icon
                        className="alert__close"
                        onClick={onClose}>
                        close
                    </Icon>
                </div>
            }
        </>
    );
};