import { Button, Textarea, TextInput } from 'react-materialize';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import './NewProject.scss';
type Props = {
    formik: any;
};

export function NewProject(props: Props) {
    const {
        formik
    } = props;

    return (
        <div className="container">
            <ProfileDropdownContainer />
            <div className="content projects">
                <div className="projects__head">
                    <TitleAndDesc 
                        title="New Project" 
                        desc="Configure your new Project" />
                </div>
                <form onSubmit={formik.handleSubmit} className="create-project">
                    <div className="create-project__wrap">
                        <div className="create-project__title">Create Project</div>
                        <div className="create-project__row">
                            <div className="create-project__item">
                                <div className="create-project__content">
                                    <div className="create-project__label">Name</div>
                                    <TextInput 
                                        type="text"
                                        placeholder="Name your Project" 
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        inputClassName={`textInputOutline ${formik.touched.name && Boolean(formik.errors.name) ? "invalid" : ""}`}
                                        validate={formik.touched.name && Boolean(formik.errors.name)}
                                        error={formik.touched.name && formik.errors.name} />
                                </div>
                            </div>
                            <div className="create-project__item">
                                <div className="create-project__content">
                                    <div className="create-project__label">Description</div>
                                    <Textarea
                                        placeholder="Project description" 
                                        //@ts-ignore
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        className={`textInputOutline`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-project__btn-row">
                        <Button
                            node="button"
                            waves="light"
                            className="btn-outline">
                            Cancel
                        </Button>
                        <Button
                            node="button"
                            //@ts-ignore
                            type="submit"
                            waves="light">
                            Create Project
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
