import React from 'react';
import './bricks.scss';

type Props = {
    title: string;
    desc?: string;
}

const TitleAndDesc:React.FC<Props> = (props) => {
    const {
        title,
        desc
    } = props;

    return (
        <div className="title__row">
            <div className="title">{title}</div>
            <div className="desc">{desc}</div>
        </div>
    );
}

export default TitleAndDesc;