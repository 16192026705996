import { Action } from "typesafe-actions"

export interface DevelopersState {
    token: string | null;
    isAuthorized: boolean;
    errorState: ErrorType;
    successState: SuccessType;
    developerInfo: DeveloperInfo | null;
}

export enum DevelopersActionTypes {
    GET_TOKEN = 'GET_TOKEN',
    FETCH_ERROR = 'FETCH_ERROR',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    IS_AUTHORIZED = 'IS_AUTHORIZED',
    GET_DEVELOPER_INFO = 'GET_DEVELOPER_INFO',
    UPDATE_DEVELOPER = 'UPDATE_DEVELOPER',
    LOGOUT = 'LOGOUT'
}

export interface ITokenAction extends Action<DevelopersActionTypes.GET_TOKEN> {
    token: string;
    isAuthorized: boolean;
}

export interface IFechSuccessAction extends Action<DevelopersActionTypes.FETCH_SUCCESS> {
    successState: SuccessType
}

export interface IFechErrorAction extends Action<DevelopersActionTypes.FETCH_ERROR> {
    errorState: ErrorType
}

export interface IAuthorizedAction extends Action<DevelopersActionTypes.IS_AUTHORIZED> {
    isAuthorized: boolean
}

export interface IGetDeveloperInfoAction extends Action<DevelopersActionTypes.GET_DEVELOPER_INFO> {
    developerInfo: DeveloperInfo
}

export interface IUpdateDeveloperInfoAction extends Action<DevelopersActionTypes.UPDATE_DEVELOPER> {
    developerInfo: DeveloperInfo
}

export interface ILogout extends Action<DevelopersActionTypes.LOGOUT>{}

export type ErrorType = {
    message: string,
    error: boolean
}

export type SuccessType = {
    message: string,
    success: boolean
}

export type DeveloperInfo = {
    balance: number;
    company: string;
    email: string;
    firstName: string;
    lastName: string;
    website: string;
    apikey: string;
    id: number;
}