import { Dispatch } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Registration from "./Registration";
import { Developers } from '../../api/types';
import { ErrorType } from '../../store/developers/types';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function RegistrationContainer(props: Props) { 
    const {
        getTokenAction,
        errorState,
        errorAction,
        isAuthorized
    } = props;

    const onErrorClose = () => {
        errorAction({
            message: "",
            error: false,
        })
    }

    const validationSchema = yup.object({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup.string().email().required("E-mail is required"),
        password: yup.string().min(14, 'Password should be of minimum 14 characters length').required("Password is required")
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            company: "",
            website: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            getTokenAction(values, true);
        },
    });

    if(isAuthorized) {
        window.location.pathname = "/";
        return <></>
    }

    return (
        <Registration 
            formik={formik}
            errorState={errorState} 
            onErrorClose={onErrorClose} />
    );
}

const mapStateToProps = (state: RootState) => ({
    errorState: state.developers.errorState,
    isAuthorized: state.developers.isAuthorized
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    getTokenAction: (payload: Developers, isAuthorized: boolean) => 
        dispatch(actions.developers.getTokenAction(payload, isAuthorized)),
    errorAction: (error: ErrorType) => 
        dispatch(actions.developers.errorAction(error)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationContainer);