import { Dispatch, useEffect, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import Dasboard from './Dasboard';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function DasboardContainer(props: Props) {
    const {
        developerInfo,
        getStatisticsAction,
        token,
        projects
    } = props;

    const [selectedProject, setSelectedProject] = useState('');

    const projectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProject(e.target.value);
    }

    useEffect(() => {
        if(token && selectedProject.length !== 0) {
            getStatisticsAction(Number(selectedProject), token);
        }
    }, [selectedProject]);

    return (
        <Dasboard 
            projectChange={projectChange}
            selectedProject={selectedProject} 
            developerInfo={developerInfo} 
            projects={projects} />
    );
}

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token,
    developerInfo: state.developers.developerInfo,
    projects: state.projects.projects
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    getStatisticsAction: (projectId: number, token: string) => 
        dispatch(actions.projects.getStatisticsAction(projectId, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DasboardContainer);