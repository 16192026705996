import { instance } from './Instance';
import { ApplicationParams } from './types';

export const ApplicationsAPI = {
    async getCurrentProjectApplications(projectId: number, token: string) {
        return await instance.get(`projects/${projectId}/applications`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            return res.data;
        });
    },
    async getCurrentApplication(applicationId: number, token: string) {
        return await instance.get(`applications/${applicationId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            return res.data;
        });
    },
    async createApplications(payload: ApplicationParams, projectId: number, token: string) {
        const { ...payloadData } = payload;
        return await instance.post(`projects/${projectId}/applications`, {...payloadData}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            return res.data;
        });
    },

    async editApplication(payload: ApplicationParams, applicationId: number, token: string) {
        const { ...payloadData } = payload;
        return await instance.patch(`applications/${applicationId}`, {...payloadData}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            return res.data;
        });
    },

    async deleteApplication(applicationId: number, token: string) {
        return await instance.delete(`applications/${applicationId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            return res.data;
        });
    },
};