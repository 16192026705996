import { Action, ActionCreator } from 'redux';
import { ThunkDispatch, ThunkAction } from "redux-thunk/index";
import { RootState } from '..';
import { ApplicationsAPI } from '../../api/ApplicationsAPI';
import { ApplicationParams } from '../../api/types';
import { DevelopersActionTypes, IFechErrorAction } from '../developers/types';
import { IApplicationsAction, ApplicationsActionTypes, ICreateApplicationAction, ICurrentApplicationAction, IApplication, IFetchApplicationAction, IEditApplicationAction } from './types';


export type ProjectsActions = 
    | IApplicationsAction
    | ICreateApplicationAction
    | ICurrentApplicationAction
    | IFetchApplicationAction
    | IEditApplicationAction;

export const getApplicationsAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IApplicationsAction>> = 
    (projectId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ApplicationsAPI.getCurrentProjectApplications(projectId, token);
            const successAction: IApplicationsAction = {
                type: ApplicationsActionTypes.GET_APLICATIONS,
                applications: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const fetchApplicationAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IApplicationsAction>> = 
    (applicationId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ApplicationsAPI.getCurrentApplication(applicationId, token);
            const successAction: IFetchApplicationAction = {
                type: ApplicationsActionTypes.FETCH_APLICATION,
                application: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export function currentApplicationAction(application: IApplication | null) {
    const action: ICurrentApplicationAction = {
        type: ApplicationsActionTypes.CURRENT_APLICATION,
        application
    }
    return action;
}

export const createApplicationAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IApplicationsAction>> = 
    (payload: ApplicationParams, projectId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ApplicationsAPI.createApplications(payload, projectId, token);

            const successAction: ICreateApplicationAction = {
                type: ApplicationsActionTypes.CREATE_APLICATION,
                application: result,
            };
            dispatch(successAction);
            window.history.back();
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.title || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const editApplicationAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IApplicationsAction>> = 
    (payload: ApplicationParams, applicationId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ApplicationsAPI.editApplication(payload, applicationId, token);

            const successAction: IEditApplicationAction = {
                type: ApplicationsActionTypes.EDIT_APLICATION,
                application: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.title || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const deleteApplicationAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IApplicationsAction>> = 
    (applicationId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {

            await ApplicationsAPI.deleteApplication(applicationId, token);

            window.history.back();
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.title || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}