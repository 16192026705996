import * as React from 'react';
import { Button, Select, Textarea, TextInput } from 'react-materialize';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import { Form, Formik } from 'formik';
import { InitialValues } from './NewAppContainer';
import './NewApp.scss';
import { ApplicationParams, Platform } from '../../api/types';
import { convertBase64 } from '../../utils/convertBase64';

type Props = {
    initialValues: InitialValues;
    validationSchema: any;
    certificate: string;
    certificateChange: (value: string) => void;
    createApplicationAction: (payload: ApplicationParams, projectId: number, token: string) => Promise<any>;
    token: string | null;
    projectId: number;
};

export function NewApp(props: Props) {
    const {
        initialValues,
        validationSchema,
        certificate,
        certificateChange,
        createApplicationAction,
        token,
        projectId
    } = props;

    const platformOptions = [
        { value: Platform.All, label: "Select from list"},
        { value: Platform.Android, label: "Android"},
        { value: Platform.IOS, label: "IOS"},
        { value: Platform.Web, label: "Web"},
        { value: Platform.Desktop, label: "Desktop"},
    ];

    return (
        <div className="container">
            <ProfileDropdownContainer />
            <div className="content projects">
                <div className="projects__head">
                    <TitleAndDesc 
                        title="New App" 
                        desc="Configure your new App" />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        if(token) {
                            values.platform = Number(values.platform);
                            createApplicationAction(values, projectId, token);
                        }
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => {

                        const p8CertificateBase64Change = 
                            async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
                        {
                            const target = event.target as HTMLInputElement;
                            const excelFile: File = (target.files as FileList)[0];
                            try {
                                const base64: string = await convertBase64(excelFile);
                                const base64Replace = base64.replace(/^data:(.*,)?/, '');
                                setFieldValue('p8CertificateBase64', base64Replace)
                            } catch(error) {
                                console.log("error:", error);
                            }
                            
                        }

                        const p12CertificateBase64Change = 
                            async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
                        {
                            const target = event.target as HTMLInputElement;
                            const excelFile: File = (target.files as FileList)[0];
                            try {
                                const base64: string = await convertBase64(excelFile);
                                const base64Replace = base64.replace(/^data:(.*,)?/, '');
                                setFieldValue('p12CertificateBase64', base64Replace)
                            } catch(error) {
                                console.log("error:", error);
                            }
                            
                        }

                        return (
                            <Form className="create-project">
                            <div className="create-project__wrap">
                                <div className="create-project__title">Create Application</div>
                                <div className="create-project__row">
                                    <div className="create-project__item">
                                        <div className="create-project__content">
                                            <div className="create-project__label">Name</div>
                                            <TextInput 
                                                placeholder="Name" 
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                inputClassName={`textInputOutline ${errors.name && touched.name ? "invalid" : ""}`}
                                                error={errors.name && touched.name ? errors.name : undefined} />
                                        </div>
                                        <div className="select create-project__content">
                                            <div className="create-project__label">Platform</div>
                                                <Select
                                                    id="platform"
                                                    multiple={false}
                                                    //@ts-ignore
                                                    value={values.platform}
                                                    onChange={handleChange}>
                                                    {platformOptions.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <div className="select-error">
                                                    {errors.platform && touched.platform ? errors.platform : undefined}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="create-project__item">
                                            <div className="create-project__content">
                                                <div className="create-project__label">Description</div>
                                                <Textarea
                                                    placeholder="Project description" 
                                                    className="textInputOutline"
                                                    //@ts-ignore
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {Number(values.platform) === 1 && 
                                    <div className="create-project__wrap android">
                                        <div className="create-project__title">Application credentials</div>
                                        <div className="create-project__row">
                                            <div className="create-project__item">
                                                <div className="create-project__content">
                                                    <div className="create-project__label">Project ID (Firebase)</div>
                                                    <TextInput 
                                                        placeholder="Project ID (Firebase)" 
                                                        inputClassName="textInputOutline" 
                                                        name="firebaseId" 
                                                        //@ts-ignore
                                                        value={values.firebaseId}
                                                        onChange={handleChange} />
                                                </div>
                                                <div className="create-project__content">
                                                    <div className="create-project__label">Project Key (Firebase)</div>
                                                    <TextInput 
                                                        placeholder="Project Key (Firebase)" 
                                                        inputClassName="textInputOutline" 
                                                        name="firebaseKey" 
                                                        value={values.firebaseKey}
                                                        onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {Number(values.platform) === 2 &&
                                    <div className="create-project__wrap">
                                        <div className="create-project__title">Application credentials</div>
                                        <div className="create-project__row">
                                            <div className="create-project__item">
                                                <div className="create-project__content">
                                                    <div className="create-project__label">Bundle ID</div>
                                                    <TextInput 
                                                        placeholder="Bundle ID" 
                                                        inputClassName="textInputOutline" 
                                                        name="bundleId" 
                                                        value={values.bundleId}
                                                        onChange={handleChange} />
                                                </div>
                                                <div className="create-project__content">
                                                    <div className="create-project__label">Certificate</div>
                                                    <Select
                                                        id="certificate"
                                                        multiple={false}
                                                        value={certificate}
                                                        onChange={(event) => certificateChange(event.target.value)}>
                                                        <option value="P8">P8</option>
                                                        <option value="P12">P12</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="create-project__row">
                                            {certificate === "P8" &&
                                                <div className="create-project__item">
                                                    <div className="create-project__content">
                                                        <div className="create-project__label">Key</div>
                                                        <div className="textInputFile">
                                                            <TextInput
                                                                label="Select file"
                                                                type="file"
                                                                inputClassName="textInputFile"
                                                                name="p8CertificateBase64" 
                                                                onChange={p8CertificateBase64Change}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="create-project__content">
                                                        <div className="create-project__label">Key ID</div>
                                                        <TextInput 
                                                            placeholder="Key ID" 
                                                            inputClassName="textInputOutline" 
                                                            name="keyId" 
                                                            value={values.keyId}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="create-project__content">
                                                        <div className="create-project__label">Team ID</div>
                                                        <TextInput 
                                                            placeholder="Team ID" 
                                                            inputClassName="textInputOutline" 
                                                            name="teamId" 
                                                            value={values.teamId}
                                                            onChange={handleChange} />
                                                    </div>
                                                </div>
                                            }
                                            {certificate === "P12" &&
                                                <div className="create-project__item">
                                                    <div className="create-project__content">
                                                        <div className="create-project__label">Key</div>
                                                        <div className="textInputFile">
                                                            <TextInput
                                                                label="Select file"
                                                                type="file"
                                                                inputClassName="textInputFile"
                                                                name="p12CertificateBase64"
                                                                onChange={p12CertificateBase64Change}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="create-project__content">
                                                        <div className="create-project__label">Password</div>
                                                        <TextInput 
                                                            type="password"
                                                            placeholder="Password" 
                                                            inputClassName="textInputOutline" 
                                                            name="p12CertificatePassword" 
                                                            value={values.p12CertificatePassword}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="create-project__btn-row">
                                    <Button
                                        node="button"
                                        waves="light"
                                        className="btn-outline">
                                        Cancel
                                    </Button>
                                    <Button
                                        node="button"
                                        waves="light"
                                        onClick={() => handleSubmit}>
                                        Create App
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                    </Formik>
            </div>
        </div>
    );
};