import { Action } from "typesafe-actions"

export interface ProjectsState {
    projects: Projects[],
    project: Projects | null,
    statistics: Statistics[]
}

export enum ProjectsActionTypes {
    GET_PROJECTS = 'GET_PROJECTS',
    CREATE_PROJECT = 'CREATE_PROJECT',
    CURRENT_PROJECT = 'CURRENT_PROJECT',
    EDIT_PROJECT = 'EDIT_PROJECT',
    GET_STATISTICS = 'GET_STATISTICS'
}

export interface IProjectsAction extends Action<ProjectsActionTypes.GET_PROJECTS> {
    projects: Projects[];
}

export interface ICurrentAction extends Action<ProjectsActionTypes.CURRENT_PROJECT> {
    project: Projects;
}

export interface ICreateProjectAction extends Action<ProjectsActionTypes.CREATE_PROJECT> {
    project: Projects;
}

export interface IEditProjectAction extends Action<ProjectsActionTypes.EDIT_PROJECT> {
    project: Projects;
}

export interface IStatisticsAction extends Action<ProjectsActionTypes.GET_STATISTICS> {
    statistics: Statistics[];
}

export interface Projects {
    id: number,
    apiKey: string,
    name: string,
    description: string
}

export type Statistics = {
    dateTimestamp: number,
    voiceCallsCount: number,
    videoCallsCount: number
}