import { Dispatch } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { NewProject } from './NewProject';
import { useFormik } from 'formik';
import * as yup from 'yup';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function NewProjectContainer(props: Props) {
    const {
        createProjectAction,
        token
    } = props;

    const validationSchema = yup.object({
        name: yup.string().required("Project name is required"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(token) {
                createProjectAction(values.name, values.description, token);
            }
        },
    });

    return (
        <NewProject
            formik={formik}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token,
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    createProjectAction: (name: string, description: string, token: string) => 
        dispatch(actions.projects.createProjectAction(name, description, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewProjectContainer);