import { ProjectsActions } from "./actions";
import { ApplicationsActionTypes, ApplicationsState } from './types';
import { Reducer } from "redux";

const defaultState: ApplicationsState = {
    applications: [],
    application: null,
};

export const projectsReducer: Reducer<ApplicationsState, ProjectsActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case ApplicationsActionTypes.GET_APLICATIONS:
            return {
                ...state,
                applications: action.applications
            };
        case ApplicationsActionTypes.FETCH_APLICATION:
            return {
                ...state,
                application: action.application
            };
        case ApplicationsActionTypes.CURRENT_APLICATION:
            return {
                ...state,
                application: action.application
            };
        case ApplicationsActionTypes.CREATE_APLICATION:
            const applications = state.applications.concat(action.application);
            return {
                ...state,
                applications
            };
        case ApplicationsActionTypes.EDIT_APLICATION:
            const objIndex = state.applications.findIndex((obj => obj.id === action.application.id));
            state.applications[objIndex] = action.application;
            return {
                ...state,
                applications: state.applications,
                application: action.application
            };
        default:
            return state;
    }
};
export default projectsReducer;