import React from 'react';
import { Button } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import { DeveloperInfo } from '../../store/developers/types';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import './Billing.scss';

type Props = {
    developerInfo: DeveloperInfo | null
}

const Billing:React.FC<Props> = (props) => {
    const { developerInfo } = props;

    return (
        <div className="container">
            <ProfileDropdownContainer />
            <div className="content">
                <TitleAndDesc 
                    title="Billing information" 
                    desc="Manage your account" />
                {developerInfo && 
                    <div className="billing">
                        <div className="billing__row">
                            <div className="billing__item">
                                <div className="billing__title">Current Ballance</div>
                                <div className="billing__price">{developerInfo.balance} <span>USD</span></div>
                                <div className="billing__desc">
                                    Credits are used for making PSTN calls and sending SMS messages.
                                </div>
                            </div>
                        </div>
                        <NavLink to="/settings">
                            <Button
                                node="button"
                                waves="light"
                                className="billing__btn"
                            >
                                Update Settings
                            </Button>
                        </NavLink>
                    </div>
                }
            </div>
        </div>
    );
}

export default Billing;