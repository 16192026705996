import { Button, TextInput } from 'react-materialize';
import { LogoIcon, HidePasswordIcon, ShowPasswordIcon } from '../../icons/icons';
import { Formik } from 'formik';
import { InitialValuesReset } from './ResetPasswordContainer';
import { DevelopersAPI } from '../../api/DevelopersAPI';
import './ResetPassword.scss';

type Props = {
    showPassword: boolean;
    togglePasswordClick: () => void;
    validationSchema: any;
    initialValues: InitialValuesReset;
    developerId: string;
    passwordResetKey: string;
};

export function ResetPassword(props: Props) {
    const { 
        showPassword,
        togglePasswordClick,
        validationSchema,
        initialValues,
        developerId,
        passwordResetKey
    } = props;

    return (
        <div className="authorization">
            <div className="authorization__logo">
                <LogoIcon/>
            </div>
            <div className="authorization__title">Reset password</div>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    DevelopersAPI.resetPasswordConfirm(developerId, passwordResetKey, values.password)
                        .then(res => {
                            if(res.status === 200) {
                                window.history.pushState({}, document.title, "/" + "authorization");
                                window.location.pathname = "/authorization";
                            }
                        }).catch(({response}) => alert(response.data.title +" status "+ response.data.status));
                    actions.setSubmitting(false);
                }}>
                    {({ errors, touched, values, handleChange, handleSubmit }) => {
                        return (
                            <div className="authorization__content">
                                <TextInput
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    label="Password"
                                    name="password"
                                    icon={
                                        <div 
                                            onClick={togglePasswordClick}>
                                            {!showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                        </div>
                                    }
                                    value={values.password}
                                    onChange={handleChange}
                                    inputClassName={`${errors.password && touched.password ? "invalid" : ""}`}
                                    error={errors.password && touched.password ? errors.password : undefined}
                                />
                                <TextInput
                                    type={showPassword ? "text" : "password"}
                                    id="repeatPassword"
                                    label="Repeat password"
                                    name="repeatPassword"
                                    icon={
                                        <div 
                                            onClick={togglePasswordClick}>
                                            {!showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                        </div>
                                    }
                                    value={values.repeatPassword}
                                    onChange={handleChange}
                                    inputClassName={`${errors.repeatPassword && touched.repeatPassword ? "invalid" : ""}`}
                                    error={errors.repeatPassword && touched.repeatPassword ? errors.repeatPassword : undefined}
                                />
                                <Button
                                    node="button"
                                    waves="light"
                                    onClick={() => handleSubmit()}
                                >
                                    Reset
                                </Button>
                            </div>
                        )
                    }}
            </Formik>
        </div>
    );
};