import React from 'react';
import { Button, Select } from 'react-materialize';
import { DeveloperInfo } from '../../store/developers/types';
import { Projects } from '../../store/projects/types';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import { AudioIcon } from '../../icons/icons';
import './Dasboard.scss';
import { GraphsChartsContainer } from '../GraphsCharts';

type Props = {
    selectedProject: string;
    projectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    developerInfo: DeveloperInfo | null;
    projects: Projects[];
}

const Dasboard:React.FC<Props> = (props) => {
    const {
        projectChange,
        selectedProject,
        developerInfo,
        projects
    } = props;

    const projectStatisticsView = () => {
        if(selectedProject.length === 0) {
            return (
                <div className="project-selected">
                    <div className="project-selected__no">No Project selected</div>
                </div>
            )
        }
        return (
            <div className="project__row">
                <div className="project__item">
                    <div className="project__title">
                        <div className="project__icon">
                            <AudioIcon />
                        </div>
                        Audio, video calling
                    </div>
                    <div className="project__sub-title">The yellow line is audio calls.</div>
                    <div className="project__sub-title">The red line is video calls.</div>
                    <div className="project__chart">
                        <GraphsChartsContainer 
                            selectedProject={selectedProject} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="container">
                <ProfileDropdownContainer />
                <div className="content">
                    <TitleAndDesc 
                        title={developerInfo ? ('Welcome ' + developerInfo.firstName + '!') : ""} 
                        desc="This is your Dashboard" />
                    <div className="filter__row">
                        <Select
                            id="selectProjectFromList"
                            multiple={false}
                            onChange={(event) => projectChange(event)}
                            value={selectedProject}>
                            <option
                                disabled
                                value=""
                            >
                                Select Project from list
                            </option>
                            {projects.map(project => (
                                <option
                                    key={'project-list-' + project.id} 
                                    value={project.id}>
                                    {project.name}
                                </option>
                            ))}
                        </Select>
                        <Button
                            node="button"
                            waves="light"
                        >
                            + Filter
                        </Button>
                    </div>
                    {projectStatisticsView()}
                </div>
            </div>
            <div className="sapmle-codes">
                <div className="sapmle-codes__row">
                    <div className="sapmle-codes__title">Sample codes that can help you</div>
                    <div className="sapmle-codes_desc">Start with a template and customize it to fit your needs</div>
                </div>
                <div className="sapmle-code">
                    <div className="sapmle-code__item">
                        <div className="sapmle-code__title">Audio calling</div>
                        <div className="sapmle-code__desc">
                            This app allows callers to say what they need, using Autopilot langauge understanding to route callers correctly.
                        </div>
                        <Button
                            node="button"
                            waves="light"
                        >
                            Get this sample code
                        </Button>
                    </div>
                    <div className="sapmle-code__item">
                        <div className="sapmle-code__title">Audio calling</div>
                        <div className="sapmle-code__desc">
                            This app allows callers to say what they need, using Autopilot langauge understanding to route callers correctly.
                        </div>
                        <Button
                            node="button"
                            waves="light"
                        >
                            Get this sample code
                        </Button>
                    </div>
                    <div className="sapmle-code__item">
                        <div className="sapmle-code__title">Audio calling</div>
                        <div className="sapmle-code__desc">
                            This app allows callers to say what they need, using Autopilot langauge understanding to route callers correctly.
                        </div>
                        <Button
                            node="button"
                            waves="light"
                        >
                            Get this sample code
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dasboard;