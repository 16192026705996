import { Action, ActionCreator } from 'redux';
import { ThunkDispatch, ThunkAction } from "redux-thunk/index";
import { RootState } from '..';
import { ProjectsAPI } from '../../api/ProjectsAPI';
import { DevelopersActionTypes, IFechErrorAction, IFechSuccessAction } from '../developers/types';
import { ICreateProjectAction, ICurrentAction, IEditProjectAction, IProjectsAction, IStatisticsAction, ProjectsActionTypes } from './types';


export type ProjectsActions = 
    | IProjectsAction
    | ICreateProjectAction
    | ICurrentAction
    | IEditProjectAction
    | IStatisticsAction;

export const getStatisticsAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IProjectsAction>> = 
    (projectId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ProjectsAPI.getProjectsStatistics(projectId, token);
            const successAction: IStatisticsAction = {
                type: ProjectsActionTypes.GET_STATISTICS,
                statistics: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const getProjectsAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IProjectsAction>> = 
    (token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ProjectsAPI.getProjects(token);
            const successAction: IProjectsAction = {
                type: ProjectsActionTypes.GET_PROJECTS,
                projects: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const currentProjectAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IProjectsAction>> = 
    (projectId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ProjectsAPI.currentProject(projectId, token);
            const successAction: ICurrentAction = {
                type: ProjectsActionTypes.CURRENT_PROJECT,
                project: result,
            };
            dispatch(successAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const createProjectAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, ICreateProjectAction>> = 
    (name: string, description: string, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ProjectsAPI.createProject(name, description, token);
            const successAction: ICreateProjectAction = {
                type: ProjectsActionTypes.CREATE_PROJECT,
                project: result,
            };
            dispatch(successAction);
            window.location.pathname = "/projects";
        }
        catch ({ response }) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const editProjectAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IProjectsAction>> = 
    (projectId: number, name: string, description: string, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            const result = await ProjectsAPI.editProject(projectId, name, description, token);
            const successStateAction: IFechSuccessAction = {
                type: DevelopersActionTypes.FETCH_SUCCESS,
                successState: {
                    message: "You have successfully edited!",
                    success: true
                },
            };

            const successAction: IEditProjectAction = {
                type: ProjectsActionTypes.EDIT_PROJECT,
                project: result,
            };
            dispatch(successAction);
            dispatch(successStateAction);
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}

export const deleteProjectAction: ActionCreator<ThunkAction<Promise<any>, RootState, string, IProjectsAction>> = 
    (projectId: number, token: string) => {
    return async (dispatch: ThunkDispatch<RootState, void, Action<any>>) => {
        try {
            await ProjectsAPI.deleteProject(projectId, token);

            // const successAction: IEditProjectAction = {
            //     type: ProjectsActionTypes.EDIT_PROJECT,
            //     project: result,
            // };
            // dispatch(successAction);
            window.location.pathname = "/projects";
        }
        catch ({response}) {
            // error log
            console.log("error:", response);
            const failAction: IFechErrorAction = {
                type: DevelopersActionTypes.FETCH_ERROR,
                errorState: {
                    message: response.data.description || "Sorry, something went wrong. Status " + response.status,
                    error: true
                }
            };
            dispatch(failAction);
        }
    }
}