import React from 'react';
import { Button, Checkbox, TextInput } from 'react-materialize';
import { Link } from 'react-router-dom';
import { LogoIcon } from '../../icons/icons';
import partner1 from '../../img/partner1.png';
import partner2 from '../../img/partner2.png'; 
import partner3 from '../../img/partner3.png';
import './Registration.scss';
import 'materialize-css';
import { Alert } from '../Alert/Alert';
type Props = {
    formik: any,
    errorState: {
        message: string;
        error: boolean;
    }
    onErrorClose: () => void;
}

const Registration:React.FC<Props> = (props) => {
    const {
        formik,
        errorState,
        onErrorClose
    } = props;

    return (
        <div className="registration">
            <div className="registration__header">
                <div className="registration__right">
                    <div className="registration__account">
                        Already have account?
                    </div>
                    <Link to="/authorization">
                        <Button
                            node="button"
                            waves="light">
                            Log in
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="registration__container">
                <div className="registration__logo"><LogoIcon/></div>
                <div className="registration__content">
                    <div className="registration__info">
                        <div className="registration__title">
                            Get started with a free Develotex account. <br />
                            No credit card required.
                        </div>
                        <ul className="registration__list">
                            <li className="registration__item">
                                <div className="registration__icon">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 0C4.03594 0 0 4.03594 0 9C0 13.9641 4.03594 18 9 18C13.9641 18 18 13.9641 18 9C18 4.03594 13.9641 0 9 0ZM13.5633 7.09453L8.68711 11.9672C8.53945 12.1148 8.34961 12.1887 8.15625 12.1887C7.96289 12.1887 7.77305 12.1148 7.62539 11.9672L5.18906 9.53086C4.89727 9.23906 4.89727 8.76445 5.18906 8.46914C5.48086 8.17734 5.95547 8.17734 6.25078 8.46914L8.15625 10.3746L12.5016 6.0293C12.7934 5.7375 13.268 5.7375 13.5633 6.0293C13.8551 6.32461 13.8551 6.79922 13.5633 7.09453Z" fill="#DF434B"/>
                                    </svg>
                                </div>
                                <span>Audio Calling</span>
                            </li>
                            <li className="registration__item">
                                <div className="registration__icon">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 0C4.03594 0 0 4.03594 0 9C0 13.9641 4.03594 18 9 18C13.9641 18 18 13.9641 18 9C18 4.03594 13.9641 0 9 0ZM13.5633 7.09453L8.68711 11.9672C8.53945 12.1148 8.34961 12.1887 8.15625 12.1887C7.96289 12.1887 7.77305 12.1148 7.62539 11.9672L5.18906 9.53086C4.89727 9.23906 4.89727 8.76445 5.18906 8.46914C5.48086 8.17734 5.95547 8.17734 6.25078 8.46914L8.15625 10.3746L12.5016 6.0293C12.7934 5.7375 13.268 5.7375 13.5633 6.0293C13.8551 6.32461 13.8551 6.79922 13.5633 7.09453Z" fill="#DF434B"/>
                                    </svg>
                                </div>
                                <span>Video Calling</span>
                            </li>
                            <li className="registration__item">
                                <div className="registration__icon">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 0C4.03594 0 0 4.03594 0 9C0 13.9641 4.03594 18 9 18C13.9641 18 18 13.9641 18 9C18 4.03594 13.9641 0 9 0ZM13.5633 7.09453L8.68711 11.9672C8.53945 12.1148 8.34961 12.1887 8.15625 12.1887C7.96289 12.1887 7.77305 12.1148 7.62539 11.9672L5.18906 9.53086C4.89727 9.23906 4.89727 8.76445 5.18906 8.46914C5.48086 8.17734 5.95547 8.17734 6.25078 8.46914L8.15625 10.3746L12.5016 6.0293C12.7934 5.7375 13.268 5.7375 13.5633 6.0293C13.8551 6.32461 13.8551 6.79922 13.5633 7.09453Z" fill="#DF434B"/>
                                    </svg>
                                </div>
                                <span>SMS Verification</span>
                            </li>
                        </ul>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="registration__form">
                        <TextInput
                            type="text"
                            id="firstName"
                            label="First Name *"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            inputClassName={formik.touched.firstName && Boolean(formik.errors.firstName) ? "invalid" : ""}
                            validate={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            error={formik.touched.firstName && formik.errors.firstName}
                        />
                        <TextInput
                            type="text"
                            id="lastName"
                            label="Last name *"
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            inputClassName={formik.touched.lastName && Boolean(formik.errors.lastName) ? "invalid" : ""}
                            validate={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            error={formik.touched.lastName && formik.errors.lastName}
                        />
                        <TextInput
                            email
                            id="email"
                            label="E-mail *"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            inputClassName={formik.touched.email && Boolean(formik.errors.email) ? "invalid" : ""}
                            validate={formik.touched.email && Boolean(formik.errors.email)}
                            error={formik.touched.email && formik.errors.email}
                        />
                        <TextInput
                            password
                            id="password"
                            label="Password (14+ Characters) *"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            inputClassName={formik.touched.password && Boolean(formik.errors.password) ? "invalid" : ""}
                            validate={formik.touched.password && Boolean(formik.errors.password)}
                            error={formik.touched.password && formik.errors.password}
                        />
                        <TextInput
                            type="text"
                            id="company"
                            label="Company"
                            name="company"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                        />
                        <TextInput
                            type="text"
                            id="website"
                            label="Website"
                            name="website"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                        />
                        <div className="registration__agreement">
                            I have read and agree to the Develotex <Link to="#">Terms of Service</Link> and <Link to="#">Privacy Policy</Link>.
                        </div>
                        <div className="registration__btn-row">
                            <Checkbox
                                checked
                                filledIn
                                label=""
                                value="Red"
                            />
                            <Alert 
                                open={errorState.error} 
                                onClose={onErrorClose}
                                message={errorState.message} 
                                type="error" />
                            <Button
                                node="button"
                                //@ts-ignore
                                type="submit"
                                waves="light"
                            >
                                Create account
                            </Button>
                        </div>
                    </form>
                    <div className="registration__partner">
                        <div className="registration__partner-title">Recommended by</div>
                        <div className="registration__partner-row">
                            <div className="registration__partner-item">
                                <img src={partner1} alt=""/>
                            </div>
                            <div className="registration__partner-item">
                                <img src={partner2} alt=""/>
                            </div>
                            <div className="registration__partner-item">
                                <img src={partner3} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registration;