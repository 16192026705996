import { ProjectsActions } from "./actions";
import { ProjectsActionTypes, ProjectsState } from './types';
import { Reducer } from "redux";

const defaultState: ProjectsState = {
    projects: [],
    project: null,
    statistics: [
        // {
        //     dateTimestamp: 1623628800,
        //     voiceCallsCount: 1,
        //     videoCallsCount: 1
        // },
        // {
        //     dateTimestamp: 1623542400,
        //     voiceCallsCount: 1,
        //     videoCallsCount: 1
        // }
    ]
};

export const projectsReducer: Reducer<ProjectsState, ProjectsActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case ProjectsActionTypes.GET_STATISTICS:
            return {
                ...state,
                statistics: action.statistics
            };
        case ProjectsActionTypes.GET_PROJECTS:
            return {
                ...state,
                projects: action.projects
            };
        case ProjectsActionTypes.CURRENT_PROJECT:
            return {
                ...state,
                project: action.project
            };
        case ProjectsActionTypes.CREATE_PROJECT:
            const projects = state.projects.concat(action.project);
            return {
                ...state,
                projects
            };
        case ProjectsActionTypes.EDIT_PROJECT:
            const objIndex = state.projects.findIndex((obj => obj.id === action.project.id));
            state.projects[objIndex] = action.project;
            return {
                ...state,
                projects: state.projects,
                project: action.project
            };
        default:
            return state;
    }
};
export default projectsReducer;