import { Button, TextInput } from 'react-materialize';
import { HidePasswordIcon, ShowPasswordIcon } from '../../icons/icons';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import { Formik } from 'formik';
import { DeveloperInfo } from '../../store/developers/types';
import { UpdateDevelopers } from '../../api/types';
import './Settings.scss';

type Props = {
    showKeySID: boolean;
    toggleKeySIDClick: () => void;
    validationSchema: any;
    developerInfo: DeveloperInfo | null;
    updateDevelopersAction: (newDeveloperInfo: UpdateDevelopers, token: string) => Promise<any>;
    token: string | null;
};

export function Settings(props: Props) {
    const {
        showKeySID,
        toggleKeySIDClick,
        validationSchema,
        developerInfo,
        updateDevelopersAction,
        token
    } = props;

    if(!developerInfo) {
        return null;
    }

    return (
        <div className="container">
            <ProfileDropdownContainer />
            <Formik
                initialValues={{
                    firstName: developerInfo.firstName,
                    lastName: developerInfo.lastName,
                    company: developerInfo.company,
                    website: developerInfo.website,
                    oldPassword: undefined,
                    password: undefined,
                    repeatPassword: undefined
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        const developer: UpdateDevelopers = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            company: values.company,
                            website: values.website,
                            oldPassword: values.oldPassword,
                            password: values.password
                        }
                        if(token) {
                            updateDevelopersAction(developer, token);
                        }
                        actions.setSubmitting(false);
                }}>
                    {({ errors, touched, values, handleChange, handleSubmit }) => {
                        return (
                            <div className="content projects">
                                <div className="projects__head">
                                    <TitleAndDesc 
                                        title="Settings" 
                                        desc="API settings and Account SID" />
                                </div>
                                <div className="create-project">
                                    <div className="create-project__wrap">
                                        <div className="create-project__title">API Credentials</div>
                                        <div className="create-project__row">
                                            <div className="create-project__item">
                                                <div className="create-project__content">
                                                    <div className="create-project__label">Account SID</div>
                                                    <TextInput 
                                                        placeholder="Account SID" 
                                                        inputClassName="textInputOutline invalid"
                                                        validate={false}
                                                        value={developerInfo.id.toString()}
                                                        disabled
                                                        onChange={(event) => console.log(event.target.value)}
                                                        error={`Used to exercise the REST API`} />
                                                </div>
                                                <div className="create-project__content">
                                                    <div className="create-project__label">Key SID</div>
                                                    <TextInput
                                                        type={showKeySID ? "text" : "password"}
                                                        placeholder="Key SID" 
                                                        inputClassName="textInputOutline invalid"
                                                        value={developerInfo.apikey}
                                                        disabled
                                                        validate={false}
                                                        onChange={(event) => console.log(event.target.value)}
                                                        error={`Keep this somewhere safe and secure`} 
                                                        icon={
                                                            <div 
                                                                onClick={toggleKeySIDClick}>
                                                                {!showKeySID ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                                            </div>
                                                        } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="settings">
                                    <div className="settings__item">
                                        <div className="create-project">
                                            <div className="create-project__wrap">
                                                <div className="create-project__title">Account settings</div>
                                                <div className="create-project__row">
                                                    <div className="create-project__item">
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">First name *</div>
                                                            <TextInput 
                                                                placeholder="First name" 
                                                                name="firstName"
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                inputClassName={`textInputOutline ${errors.firstName && touched.firstName ? "invalid" : ""}`}
                                                                error={errors.firstName && touched.firstName ? errors.firstName : undefined} />
                                                        </div>
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">Last name *</div>
                                                            <TextInput 
                                                                placeholder="Last name" 
                                                                name="lastName"
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                inputClassName={`textInputOutline ${errors.lastName && touched.lastName ? "invalid" : ""}`}
                                                                error={errors.lastName && touched.lastName ? errors.lastName : undefined} />
                                                        </div>
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">Email *</div>
                                                            <TextInput 
                                                                placeholder="Email *" 
                                                                inputClassName="textInputOutline"
                                                                disabled
                                                                value={developerInfo.email} />
                                                        </div>
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">Company</div>
                                                            <TextInput 
                                                                placeholder="Company" 
                                                                inputClassName="textInputOutline"
                                                                name="company" 
                                                                value={values.company}
                                                                onChange={handleChange} />
                                                        </div>
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">Website</div>
                                                            <TextInput 
                                                                placeholder="Website" 
                                                                inputClassName="textInputOutline"
                                                                name="website" 
                                                                value={values.website}
                                                                onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="settings__item">
                                        <div className="create-project">
                                            <div className="create-project__wrap">
                                                <div className="create-project__title">Change password</div>
                                                <div className="create-project__row">
                                                    <div className="create-project__item">
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">Old password</div>
                                                            <TextInput 
                                                                type="password"
                                                                placeholder="Old password" 
                                                                inputClassName="textInputOutline"
                                                                name="oldPassword"
                                                                value={values.oldPassword}
                                                                onChange={handleChange} />
                                                        </div>
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">New password</div>
                                                            <TextInput 
                                                                type="password"
                                                                placeholder="New password" 
                                                                name="password"
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                inputClassName={`textInputOutline ${errors.password && touched.password ? "invalid" : ""}`}
                                                                error={errors.password && touched.password ? errors.password : undefined} />
                                                        </div>
                                                        <div className="create-project__content">
                                                            <div className="create-project__label">Repeat password</div>
                                                            <TextInput 
                                                                type="password"
                                                                placeholder="Repeat password"
                                                                name="repeatPassword"
                                                                value={values.repeatPassword}
                                                                onChange={handleChange}
                                                                inputClassName={`textInputOutline ${errors.repeatPassword && touched.repeatPassword ? "invalid" : ""}`}
                                                                error={errors.repeatPassword && touched.repeatPassword ? errors.repeatPassword : undefined}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="settings__btn-row">
                                    <Button
                                        node="button"
                                        waves="light"
                                        className="settings__btn"
                                        onClick={() => handleSubmit()}
                                    >
                                        Update Settings
                                    </Button>
                                </div>
                            </div>
                        )
                    }}
            </Formik>
        </div>
    );
};