import { instance } from './Instance';
import { Developers, UpdateDevelopers } from './types';

export const DevelopersAPI = {
    async developers(payload: Developers) {
        const { ...payloadData } = payload;
        return await instance.post(`developers`, {...payloadData}).then(res => {
            return res.data;
        });
    },

    async login(email: string, password: string) {
        return await instance.put(`developers/login`, {email, password}).then(res => {
            return res.data;
        });
    },

    async getDevelopers(token: string) {
        return await instance.get(`developers`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            return res.data;
        });
    },

    async updateDevelopers(payload: UpdateDevelopers, token: string) {
        const { ...payloadData } = payload;
        return await instance.patch(`developers`, {...payloadData}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    },

    async resetPasswordInitiate(email: string) {
        return await instance.post(`developers/reset-password-initiate`, {email}).then(res => {
            return res.data;
        });
    },

    async resetPasswordConfirm(developerId: string, passwordResetKey: string, newPassword: string) {
        return await instance.post(`developers/${developerId}/reset-password-confirm`, {passwordResetKey, newPassword});
    },

    async changePassword(oldPassword: string, password: string) {
        return await instance.patch(`developers`, {oldPassword, password});
    },
};
