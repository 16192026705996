import { Button, Textarea, TextInput } from 'react-materialize';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import { DeleteIcon, PrevIcon } from '../../icons/icons';
import { useHistory } from 'react-router';
import { Projects } from '../../store/projects/types';
import { NavLink } from 'react-router-dom';
import ApplicationTableContainer from '../ApplicationTable/ApplicationTableContainer';
type Props = {
    project: Projects | null;
    formik: any;
    deleteOpenClick: () => void;
};

export function Applications(props: Props) {
    const history = useHistory();

    const {
        project,
        formik,
        deleteOpenClick
    } = props;

    if(!project) {
        return null;
    }

    return (
        <div className="container">
            <ProfileDropdownContainer />
            <div className="content projects">
                <Button
                    flat
                    node="button"
                    waves="light"
                    className="btn__go-back"
                    icon={
                        <PrevIcon />
                    }
                    onClick={() => history.push('/projects')}
                    >
                    Back to Projects list
                </Button> 
                <div className="projects__head">
                    <TitleAndDesc 
                        title={project.name} />
                    <NavLink className="menu__link" to={`/projects/${project.id}/new-app`} exact>
                        <Button
                            node="button"
                            waves="light"
                            className="projects__btn">
                            + Add App
                        </Button>
                    </NavLink>
                </div>
                <div className="create-project">
                    <div className="create-project__wrap">
                        <div className="create-project__title">Project info</div>
                        <div className="create-project__row">
                            <div className="create-project__item">
                                <div className="create-project__content">
                                    <div className="create-project__label">ID</div>
                                    <TextInput 
                                        id="id"
                                        placeholder="ID" 
                                        inputClassName="textInputOutline" 
                                        value={project.id.toString()}
                                        disabled 
                                        onChange={() => console.log()} />
                                </div>
                                <div className="create-project__content">
                                    <div className="create-project__label">Key</div>
                                    <TextInput 
                                        id="key"
                                        placeholder="Key" 
                                        inputClassName="textInputOutline" 
                                        value={project.apiKey} 
                                        disabled
                                        onChange={() => console.log()} />
                                </div>
                                <div className="create-project__content">
                                    <div className="create-project__label">Name</div>
                                    <TextInput 
                                        type="text"
                                        placeholder="Name" 
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        inputClassName={`textInputOutline ${formik.touched.name && Boolean(formik.errors.name) ? "invalid" : ""}`}
                                        validate={formik.touched.name && Boolean(formik.errors.name)}
                                        error={formik.touched.name && formik.errors.name} />
                                </div>
                            </div>
                            <div className="create-project__item">
                                <div className="create-project__content">
                                    <div className="create-project__label">Description</div>
                                    <Textarea
                                        placeholder="Project description" 
                                        //@ts-ignore
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        className={`textInputOutline`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-project__btn-row">
                        <Button
                            flat
                            node="button"
                            waves="light"
                            icon={
                                <DeleteIcon />
                            }
                            onClick={deleteOpenClick}
                            >
                            Delete Project
                        </Button> 
                        <Button
                            node="button"
                            waves="light"
                            onClick={formik.handleSubmit}>
                            Update Project Settings
                        </Button>
                    </div>
                </div>
                <ApplicationTableContainer />
            </div>
        </div>
    );
};