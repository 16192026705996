import { Dispatch, useEffect } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ProjectTable } from './ProjectTable';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function ProjectTableContainer(props: Props) {
    const { 
        projects
    } = props;

    return (
        <ProjectTable 
            projects={projects} />
    );
};

const mapStateToProps = (state: RootState) => ({
    projects: state.projects.projects
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    getProjectsAction: (token: string) => 
        dispatch(actions.projects.getProjectsAction(token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectTableContainer)