import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { GraphsCharts } from './GraphsCharts';

type Props = 
    ReturnType<typeof mapStateToProps> & {
    selectedProject: string;
};
function GraphsChartsContainer(props: Props) {
    const { selectedProject, statistics } = props;
    const [dataVoicePoints, setDataVoicePoints] = useState<any[]>([]);
    const [dataVideoPoints, setDataVideoPoints] = useState<any[]>([]);
    const options = {
        animationEnabled: true,
        axisY: {
            interval: 5
        },
        axisX: {
            interval: 1,
            valueFormatString: "DD MMM"
        },
        height: 350,
        data: [
            {
                markerColor: "#F2C94C",
                color: "#F2C94C",
                type: "line",
                toolTipContent: "{x}: {y}",
                dataPoints: dataVoicePoints
            },
            {
                markerColor: "#DF434B",
                color: "#DF434B",
                type: "line",
                toolTipContent: "{x}: {y}",
                dataPoints: dataVideoPoints
            }
        ]
    }

    useEffect(() => {
        let resultVoice: any[] = [];
        let resultVideo: any[] = [];
        for (var i = 0; i < statistics.length; i++) {
            resultVoice.push({
                x: new Date(statistics[i].dateTimestamp * 1000),
                y: statistics[i].voiceCallsCount,
            });
            resultVideo.push({
                x: new Date(statistics[i].dateTimestamp * 1000),
                y: statistics[i].videoCallsCount,
            });
        }
        setDataVoicePoints(resultVoice);
        setDataVideoPoints(resultVideo);
    }, [selectedProject]);

    if(statistics.length === 0) {
        return (
            <div className="graphs-charts-empty">
                No statistics on video and audio
            </div>
        )
    }

    return (
        <GraphsCharts 
            options={options} />
    );
};

const mapStateToProps = (state: RootState) => ({
    statistics: state.projects.statistics
});

export default connect(
    mapStateToProps,
)(GraphsChartsContainer);