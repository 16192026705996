import { Dispatch, useEffect, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { CurrentApplication } from './CurrentApplication';
import * as yup from 'yup';
import { ApplicationParams } from '../../api/types';
import DeleteApplicationModal from '../DeleteApplicationModal/DeleteApplicationModal';

type Props =
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function CurrentApplicationContainer(props: Props) {
    //@ts-ignore
    const { applicationId } = props.match.params;
    const {
        editApplicationAction,
        token,
        application,
        fetchApplicationAction
    } = props;

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [certificate, setCertificate] = useState("");

    const deleteOpenClick = () => {
        setDeleteOpen(true);
    }

    const deleteCloseClick = () => {
        setDeleteOpen(false);
    }

    const certificateChange = (value: string) => {
        setCertificate(value);
    }

    useEffect(() => {
        if(token) {
            fetchApplicationAction(applicationId, token);
        }
    }, [applicationId]);

    useEffect(() => {
        if(application) {
            if(application.isP8CertificateUploaded === true) {
                setCertificate("P8");
            }
            if(application.isP12CertificateUploaded === true) {
                setCertificate("P12");
            }
        }
    }, [application]);


    const validationSchema = yup.object().shape({
        name: yup.string().required("Application name is required"),
        platform: yup.string().required("Platform name is required"),
    });
    
    return (
        <>
            <CurrentApplication 
                deleteOpenClick={deleteOpenClick}
                validationSchema={validationSchema} 
                certificate={certificate} 
                certificateChange={(value: string) => 
                    certificateChange(value)} 
                editApplicationAction={editApplicationAction} 
                token={token} 
                application={application} />
            {application && 
                <DeleteApplicationModal 
                    open={deleteOpen} 
                    applicationName={application.name} 
                    deleteCloseClick={deleteCloseClick} 
                    applicationId={applicationId} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token,
    application: state.applications.application
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    fetchApplicationAction: (applicationId: number, token: string) => 
        dispatch(actions.applications.fetchApplicationAction(applicationId, token)),
    editApplicationAction: (payload: ApplicationParams, projectId: number, token: string) => 
        dispatch(actions.applications.editApplicationAction(payload, projectId, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentApplicationContainer);