import { Table } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import { AudioIcon, DoneIcon, VideoIcon } from '../../icons/icons';
import { Projects } from '../../store/projects/types';
import './ProjectTable.scss';

type Props = {
    projects: Projects[];
};

export function ProjectTable(props: Props) {
    const { projects } = props;

    const emptyProjects = () => {
        if(projects.length === 0) {
            return (
                <tr>
                    <td colSpan={4}>
                        <div className="empty-projects">No projects</div>
                    </td>
                </tr>
            );
        }
    }

    return (
        <Table
            className="projects-table striped">
            <thead>
                <tr>
                    <th data-field="id" style={{minWidth: 80}}>
                        ID
                    </th>
                    <th data-field="name" style={{minWidth: 270}}>
                        Project name
                    </th>
                    <th data-field="price">
                        Description
                    </th>
                    <th data-field="price" style={{minWidth: 230}}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {emptyProjects()}
                {projects.map(project => (
                    <tr key={project.id}>
                        <td>
                            {project.id}
                        </td>
                        <td>
                            <NavLink 
                                className="menu__link" 
                                to={`projects/${project.id}`} 
                                exact>
                                <strong>{project.name}</strong>
                            </NavLink>
                        </td>
                        <td>
                            {project.description}
                        </td>
                        <td>
                            <div className="projects-table__actions">
                                <div className="projects-table__item audio-icon">
                                    <AudioIcon />
                                </div>
                                <div className="projects-table__item video-icon">
                                    <VideoIcon />
                                </div>
                                <div className="projects-table__item done-icon">
                                    <DoneIcon />
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};