import { Dispatch, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { Button, Icon, Modal, TextInput } from 'react-materialize';
import { DevelopersAPI } from '../../api/DevelopersAPI';
import './ForgotPassword.scss';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    open: boolean;
    forgotCloseClick: () => void;
};

function ForgotPassword(props: Props) {
    const {
        open,
        forgotCloseClick,
    } = props;

    const [email, setEmail] = useState("");
    const [confirm, setConfirm] = useState(false);

    const forgotPasswordClick = () => {
        DevelopersAPI.resetPasswordInitiate(email).then(data => {
            setConfirm(true);
        }).catch(({response}) => console.log(response.data.message));
    }

    return (
        <Modal
            bottomSheet={false}
            fixedFooter={false}
            id="forgotPassword"
            open={open}
            options={{
                dismissible: true,
                endingTop: '25%',
                inDuration: 250,
                opacity: 0.5,
                outDuration: 250,
                startingTop: '4%',
                onCloseEnd: () => forgotCloseClick()
            }}>
            <div className="forgot-password">
                <Icon
                    className="forgot-password__close"
                    onClick={forgotCloseClick}>
                    close
                </Icon>
                {confirm ? 
                    <div className="verification">
                        <div className="verification__icon">
                            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="70" height="70" rx="35" fill="#6FCF97"/>
                                <path d="M26 35.5L32 42L44.5 28" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                        </div>
                        <p className="verification__text">
                            An email has been sent to the address you have provided.Please follow the link in the email to complete your password reset request
                        </p>
                        <div className="verification__wrap">
                            <Button
                                node="button"
                                waves="light"
                                className="verification__btn"
                                onClick={forgotCloseClick}>
                                I got it
                            </Button>
                        </div>
                    </div>                   
                :
                    <>
                        <p className="forgot-password__text">
                            Please enter your email address below to receive a password change confirmation email.
                        </p>
                        <TextInput 
                            type="email"
                            id="E-mail"
                            label="E-mail"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                        <div className="forgot-password__wrap">
                            <Button
                                node="button"
                                waves="light"
                                className="forgot-password__btn"
                                onClick={forgotPasswordClick}>
                                Reset
                            </Button>
                        </div>
                    </>
                }
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    deleteProjectAction: (projectId: number, token: string) => 
        dispatch(actions.projects.deleteProjectAction(projectId, token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword)