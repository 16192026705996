import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import Menu from './Menu';

type Props = 
    ReturnType<typeof mapStateToProps>;

function MenuContainer(props: Props) {
    const {
        projects,
        project
    } = props;

    useEffect(() => {
    }, [project]);

    return (
        <Menu 
            projects={projects} />
    );
}

const mapStateToProps = (state: RootState) => ({
    projects: state.projects.projects,
    project: state.projects.project
});

export default connect(
    mapStateToProps
)(MenuContainer);