import React from 'react';
import { Button, TextInput } from 'react-materialize';
import TitleAndDesc from '../bricks/TitleAndDesc';
import { ProfileDropdownContainer } from '../ProfileDropdown';
import './Support.scss';
type Props = {
    formik: any,
};

export function Support(props: Props) {
    const { formik } = props;

    return (
        <div className="container">
            <ProfileDropdownContainer />
            <div className="content">
                <TitleAndDesc 
                    title="Support" 
                    desc="Find the right solution for you" />
                <div className="support">
                    <div className="support__item">
                        <div className="support__title">Technical Support</div>
                        <ul className="support__ul">
                            <div className="support__li">Find the right solution for you</div>
                            <div className="support__li">Explain options for pricing</div>
                            <div className="support__li">Connect you with helpful resources</div>
                        </ul>
                    </div>
                    <div className="support__item">
                        <form onSubmit={formik.handleSubmit} className="registration__form">
                            <TextInput
                                type="text"
                                id="name"
                                label="First name *"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                inputClassName={formik.touched.name && Boolean(formik.errors.name) ? "invalid" : ""}
                                validate={formik.touched.name && Boolean(formik.errors.name)}
                                error={formik.touched.name && formik.errors.name}
                            />
                            <TextInput
                                type="text"
                                id="lastName"
                                label="Last name *"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                inputClassName={formik.touched.lastName && Boolean(formik.errors.lastName) ? "invalid" : ""}
                                validate={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                error={formik.touched.lastName && formik.errors.lastName}
                            />
                            <TextInput
                                email
                                id="email"
                                label="E-mail *"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                inputClassName={formik.touched.email && Boolean(formik.errors.email) ? "invalid" : ""}
                                validate={formik.touched.email && Boolean(formik.errors.email)}
                                error={formik.touched.email && formik.errors.email}
                            />
                            <TextInput
                                type="text"
                                id="phoneNumber"
                                label="Phone number*"
                                name="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                inputClassName={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) ? "invalid" : ""}
                                validate={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                            />
                            <TextInput
                                type="text"
                                id="counrty"
                                label="Counrty*"
                                name="counrty"
                                value={formik.values.counrty}
                                onChange={formik.handleChange}
                                inputClassName={formik.touched.counrty && Boolean(formik.errors.counrty) ? "invalid" : ""}
                                validate={formik.touched.counrty && Boolean(formik.errors.counrty)}
                                error={formik.touched.counrty && formik.errors.counrty}
                            />
                            <div className="registration__btn-row">
                                <Button
                                    node="button"
                                    //@ts-ignore
                                    type="submit"
                                    waves="light"
                                >
                                    Let’s talk
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};