import { combineReducers } from 'redux';

import app from './app/reducer';
import developers from './developers/reducer';
import projects from './projects/reducer';
import applications from './applications/reducer';

const rootReducer = combineReducers({
    app,
    developers,
    projects,
    applications
});


export default rootReducer;