import { Dispatch, useState } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import Authorization from './Authorization';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ErrorType } from '../../store/developers/types';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function AuthorizationContainer(props: Props) {
    const {
        loginAction,
        errorState,
        errorAction,
        isAuthorized
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

    const forgotOpenClick = () => {
        setForgotPasswordOpen(true);
    }

    const forgotCloseClick = () => {
        setForgotPasswordOpen(false);
    }

    const togglePasswordClick = () => {
        setShowPassword(!showPassword);
    }
    const validationSchema = yup.object({
        email: yup.string().email().required("E-mail is required"),
        password: yup.string().min(14, 'Password should be of minimum 14 characters length').required("Password is required")
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            loginAction(values.email, values.password, true);
        },
    });

    const onErrorClose = () => {
        errorAction({
            message: "",
            error: false,
        })
    }

    if(isAuthorized) {
        window.location.pathname = "/";
        return <></>
    }

    return (
        <>
            <Authorization 
                formik={formik} 
                showPassword={showPassword} 
                togglePasswordClick={togglePasswordClick} 
                errorState={errorState} 
                onErrorClose={onErrorClose} 
                forgotOpenClick={forgotOpenClick} />
            <ForgotPassword 
                open={forgotPasswordOpen} 
                forgotCloseClick={forgotCloseClick} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    errorState: state.developers.errorState,
    isAuthorized: state.developers.isAuthorized
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    loginAction: (email: string, password: string, isAuthorized: boolean) => 
        dispatch(actions.developers.loginAction(email, password, isAuthorized)),
    errorAction: (error: ErrorType) => 
        dispatch(actions.developers.errorAction(error)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthorizationContainer);