import { Dispatch, useEffect } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { Projects } from './Projects';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function ProjectsContainer(props: Props) {
    const {
    } = props;

    return (
        <Projects />
    );
};

const mapStateToProps = (state: RootState) => ({
    
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsContainer);