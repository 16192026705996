import { Dispatch, useEffect } from 'react';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Switch, Route, BrowserRouter, useHistory  } from 'react-router-dom';
import { AuthorizationContainer } from '../Authorization';
import { BillingContainer } from '../Billing';
import { DasboardContainer } from '../Dasboard';
import { MenuContainer } from '../Menu';
import { NewAppContainer } from '../NewApp';
import { NewProjectContainer } from '../NewProject';
import { ProjectsContainer } from '../Projects';
import { RegistrationContainer } from '../Registration';
import { SettingsContainer } from '../Settings';
import { SupportContainer } from '../Support';
import { ApplicationsContainer } from '../Applications';
import { actions, RootState } from '../../store';
import { ErrorType, SuccessType } from '../../store/developers/types';
import { Alert } from '../Alert/Alert';
import CurrentApplicationContainer from '../CurrentApplication/CurrentApplicationContainer';
import { ResetPasswordContainer } from '../ResetPassword';
import './App.scss';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function App(props: Props) {
    let history = useHistory();
    const {
        token,
        isAuthorizedAction,
        isAuthorized,
        getDevelopersAction,
        getProjectsAction,
        errorAction,
        errorState,
        successAction,
        successState
    } = props;

    useEffect(() => {
        if(token) {
            getDevelopersAction(token);
            getProjectsAction(token);
        }
        isAuthorizedAction(Boolean(token));
    }, [token]);

    const onSuccessClose = () => {
        successAction({
            message: "",
            success: false,
        })
    }

    const onErrorClose = () => {
        errorAction({
            message: "",
            error: false,
        })
    }

    if(
        !isAuthorized 
        && !history.location.pathname.match(/registration/) 
        && !history.location.pathname.match(/restore-password/)
        && !history.location.pathname.match(/authorization/)
    ) {
        return <AuthorizationContainer />
    }

    return (
        <BrowserRouter>
            <div className="wrapper">
                {
                    !history.location.pathname.match(/registration/) 
                    && !history.location.pathname.match(/authorization/)
                    && !history.location.pathname.match(/restore-password/) &&
                    <MenuContainer />
                }
                <Switch>
                    <Route 
                        path="/" 
                        component={DasboardContainer} 
                        exact />
                    <Route 
                        path="/registration" 
                        component={RegistrationContainer} 
                        exact />
                    <Route 
                        path="/authorization" 
                        component={AuthorizationContainer} 
                        exact />
                    <Route 
                        path="/billing" 
                        component={BillingContainer} 
                        exact />
                    <Route 
                        path="/projects" 
                        component={ProjectsContainer} 
                        exact />
                    <Route
                        path="/projects/:projectId"
                        component={ApplicationsContainer} 
                        exact />
                    <Route 
                        path="/projects/:projectId/new-app" 
                        component={NewAppContainer} 
                        exact />
                    <Route
                        path="/projects/:projectId/:applicationId"
                        component={CurrentApplicationContainer} 
                        exact />
                    <Route 
                        path="/new-project" 
                        component={NewProjectContainer} 
                        exact />
                    <Route 
                        path="/vimes" 
                        component={ApplicationsContainer} 
                        exact />
                    <Route 
                        path="/settings" 
                        component={SettingsContainer} 
                        exact />
                    <Route 
                        path="/support" 
                        component={SupportContainer} 
                        exact />
                    <Route 
                        path="/restore-password" 
                        component={ResetPasswordContainer} 
                        exact />
                </Switch>
                <Alert 
                    open={successState.success} 
                    onClose={onSuccessClose}
                    message={successState.message} 
                    type="success" />
                <Alert 
                    open={errorState.error} 
                    onClose={onErrorClose}
                    message={errorState.message} 
                    type="error" />
            </div>
        </BrowserRouter>
    );
}

const mapStateToProps = (state: RootState) => ({
    token: state.developers.token,
    isAuthorized: state.developers.isAuthorized,
    errorState: state.developers.errorState,
    successState: state.developers.successState
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    isAuthorizedAction: (isAuthorized: boolean) => 
        dispatch(actions.developers.isAuthorizedAction(isAuthorized)),
    getDevelopersAction: (token: string) => 
        dispatch(actions.developers.getDevelopersAction(token)),
    getProjectsAction: (token: string) => 
        dispatch(actions.projects.getProjectsAction(token)),
    errorAction: (error: ErrorType) => 
        dispatch(actions.developers.errorAction(error)),
    successAction: (success: SuccessType) => 
        dispatch(actions.developers.successAction(success)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);