import React from 'react';
import CanvasJSReact from '../../utils/Canvasjs/canvasjs.react';
import './GraphsCharts.scss';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
    options: any;
};

export function GraphsCharts(props: Props) {
    const { options } = props;
    
    return (
        <div className="сanvasJSChart">
            <CanvasJSChart options = {options} />
        </div>
    );
};