import { useState } from "react";
import { ResetPassword } from "./ResetPassword";
import * as yup from 'yup';

type Props = {
  
};

export interface InitialValuesReset {
    password: string;
    repeatPassword: string;
}

function ResetPasswordContainer(props: Props) {
    //@ts-ignore
    const { search } = props.location;
    const [showPassword, setShowPassword] = useState(false);

    const initialValues: InitialValuesReset = { 
        password: "",
        repeatPassword: ""
    };

    const validationSchema = yup.object().shape({
        password: yup.string().min(14, 'Password should be of minimum 14 characters length').required("Password is required"),
        repeatPassword: yup.string().when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
              [yup.ref("password")],
              "Both password need to be the same"
            )
        })
    });

    const developerId = search && search.match(/=([\s\S]+?)&/)[1];
    const passwordResetKey = search && search.match(/[^=]*$/)[0];

    const togglePasswordClick = () => {
        setShowPassword(!showPassword);
    }

    return (
        <ResetPassword 
            showPassword={showPassword} 
            togglePasswordClick={togglePasswordClick}
            initialValues={initialValues}
            validationSchema={validationSchema}
            developerId={developerId}
            passwordResetKey={passwordResetKey} />
    );
};

export default ResetPasswordContainer;