import React from 'react';
import { Button, Checkbox, TextInput } from 'react-materialize';
import { Link } from 'react-router-dom';
import { LogoIcon, HidePasswordIcon, ShowPasswordIcon } from '../../icons/icons';
import partner1 from '../../img/partner1.png';
import partner2 from '../../img/partner2.png'; 
import partner3 from '../../img/partner3.png';
import { ErrorType } from '../../store/developers/types';
import { Alert } from '../Alert/Alert';
import './Authorization.scss';

type Props = {
    formik: any;
    showPassword: boolean;
    togglePasswordClick: () => void;
    errorState: ErrorType;
    onErrorClose: () => void;
    forgotOpenClick: () => void;
}

const Authorization:React.FC<Props> = (props) => {
    const {
        formik,
        showPassword,
        togglePasswordClick,
        errorState,
        onErrorClose,
        forgotOpenClick
    } = props;
    
    return (
        <div className="authorization">
            <div className="authorization__logo">
                <LogoIcon/>
            </div>
            <div className="authorization__title">Login</div>
            <form onSubmit={formik.handleSubmit} className="authorization__content">
                <TextInput
                    email
                    id="email"
                    label="E-mail"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    inputClassName={formik.touched.email && Boolean(formik.errors.email) ? "invalid" : ""}
                    validate={formik.touched.email && Boolean(formik.errors.email)}
                    error={formik.touched.email && formik.errors.email}
                />
                <TextInput
                    type={showPassword ? "text" : "password"}
                    id="password"
                    label="Password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    inputClassName={formik.touched.password && Boolean(formik.errors.password) ? "invalid" : ""}
                    validate={formik.touched.password && Boolean(formik.errors.password)}
                    error={formik.touched.password && formik.errors.password}
                    icon={
                        <div 
                            onClick={togglePasswordClick}>
                            {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                        </div>
                    }
                />
                <div className="authorization__forgot">
                    <Checkbox
                        checked
                        filledIn
                        id="logged"
                        label="Keep me logged in"
                        value="Keep"
                    />
                    <div 
                        className="authorization__link"
                        onClick={forgotOpenClick}>
                        Forgot password?
                    </div>
                </div>
                <div className="authorization__create">
                    Don’t have a Develotex account? <Link to="/registration" className="authorization__create-link">Create an account</Link>
                </div>
                <Button
                    node="button"
                    //@ts-ignore
                    type="submit"
                    waves="light"
                >
                    Log in
                </Button>
            </form>
            <div className="registration__partner">
                <div className="registration__partner-title">Recommended by</div>
                <div className="registration__partner-row">
                    <div className="registration__partner-item">
                        <img src={partner1} alt=""/>
                    </div>
                    <div className="registration__partner-item">
                        <img src={partner2} alt=""/>
                    </div>
                    <div className="registration__partner-item">
                        <img src={partner3} alt=""/>
                    </div>
                </div>
            </div>
            <Alert 
                open={errorState.error} 
                onClose={onErrorClose}
                message={errorState.message} 
                type="error" />
        </div>
    );
}

export default Authorization;