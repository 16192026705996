import { instance } from './Instance';

export const ProjectsAPI = {
    async getProjectsStatistics(projectId: number, token: string) {
        return await instance.get(`projects/${projectId}/stats`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            return res.data;
        });
    },

    async getProjects(token: string) {
        return await instance.get(`projects`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            return res.data;
        });
    },

    async currentProject(projectId: number, token: string) {
        return await instance.get(`projects/${projectId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            return res.data;
        });
    },

    async createProject(name: string, description: string, token: string) {
        return await instance.post(`projects`, {name, description}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            return res.data;
        });
    },

    async editProject(projectId: number, name: string, description: string, token: string) {
        return await instance.patch(`projects/${projectId}`, {name, description}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            return res.data;
        });
    },

    async deleteProject(projectId: number, token: string) {
        return await instance.delete(`projects/${projectId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            return res.data;
        });
    },

};