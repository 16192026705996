import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import Billing from './Billing';

type Props =
    ReturnType<typeof mapStateToProps>;

function BillingContainer(props: Props) {
    const { developerInfo } = props;

    return (
        <Billing 
            developerInfo={developerInfo} />
    );
}

const mapStateToProps = (state: RootState) => ({
    developerInfo: state.developers.developerInfo
});

export default connect(
    mapStateToProps
)(BillingContainer);