import { Action } from "typesafe-actions"
import { Platform } from "../../api/types";

export interface ApplicationsState {
    applications: IApplication[],
    application: IApplication | null
}

export enum ApplicationsActionTypes {
    GET_APLICATIONS = 'GET_APLICATIONS',
    CURRENT_APLICATION = 'CURRENT_APLICATION',
    CREATE_APLICATION = 'CREATE_APLICATION',
    FETCH_APLICATION = 'FETCH_APLICATION',
    EDIT_APLICATION = 'EDIT_APLICATION',
}

export interface IApplicationsAction extends Action<ApplicationsActionTypes.GET_APLICATIONS> {
    applications: IApplication[];
}

export interface IFetchApplicationAction extends Action<ApplicationsActionTypes.FETCH_APLICATION> {
    application: IApplication;
}

export interface ICurrentApplicationAction extends Action<ApplicationsActionTypes.CURRENT_APLICATION> {
    application: IApplication | null;
}

export interface ICreateApplicationAction extends Action<ApplicationsActionTypes.CREATE_APLICATION> {
    application: IApplication;
}

export interface IEditApplicationAction extends Action<ApplicationsActionTypes.EDIT_APLICATION> {
    application: IApplication;
}

export interface IApplication {
    id: number,
    name: string,
    platform: Platform,
    description: string,
    appleBundleId: string | null,
    isP12CertificateUploaded: boolean,
    p12CertificatePassword: string | null,
    isP8CertificateUploaded: boolean,
    appleKeyId: string | null,
    appleTeamId: string | null,
    androidFirebaseId: number | null,
    androidFirebaseKey: string | null
}