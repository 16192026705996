import { Table } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import { Platform } from '../../api/types';
import { AudioIcon, DoneIcon, VideoIcon } from '../../icons/icons';
import { IApplication } from '../../store/applications/types';
import { Projects } from '../../store/projects/types';
import './ApplicationTable.scss';

type Props = {
    applications: IApplication[];
    project: Projects | null;
};

export function ApplicationTable(props: Props) {
    const { applications, project } = props;

    const platformName = (platform: Platform) => {
        switch (platform) {
            case 1:
              return "Adndroid"
            case 2:
                return "IOS"
            case 3:
                return "Web"
            case 4:
                return "Desktop"
            default:
              return platform;
        }
    }

    if(applications.length === 0) {
        return null;
    }

    return (
        <Table
            className="projects-table striped">
            <thead>
                <tr>
                    <th data-field="id" style={{minWidth: 120}}>
                        App ID
                    </th>
                    <th data-field="name" style={{minWidth: 270}}>
                        App name
                    </th>
                    <th data-field="Platform" style={{minWidth: 200}}>
                        Platform
                    </th>
                    <th data-field="Description">
                        Description
                    </th>
                    <th data-field="price" style={{minWidth: 230}}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {applications.map(application => (
                    <tr key={application.id}>
                        <td>
                            {application.id}
                        </td>
                        <td>
                            <NavLink className="menu__link" to={`/projects/${project && project.id}/${application.id}`}>
                                <strong>{application.name}</strong>
                            </NavLink>
                        </td>
                        <td>
                            {platformName(application.platform)}
                        </td>
                        <td>
                            {application.description}
                        </td>
                        <td>
                            <div className="projects-table__actions">
                                <div className="projects-table__item audio-icon">
                                    <AudioIcon />
                                </div>
                                <div className="projects-table__item video-icon">
                                    <VideoIcon />
                                </div>
                                <div className="projects-table__item done-icon">
                                    <DoneIcon />
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};