export interface AppState {
    showPhoneControl : boolean;
}

export enum AppActionTypes {
    CHANGE_PHONE_CONTROL_STATE = 'CHANGE_PHONE_CONTROL_STATE',
}

export type InputType = {
    bind: {
        value: string;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    value: string;
    clear: () => void;
}